.horizontal-timeline-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 40px 0 10px; /* Increased top padding to make space for the legend */
    position: relative;
}

.horizontal-timeline {
    display: inline-flex;
    align-items: flex-start; /* Align the events to the top of the timeline */
    width: 100%;
}

.timeline-slot {
    display: inline-block;
    margin: 0 20px;
    text-align: center;
    position: relative;
}

.timeline-header {
    margin-bottom: 10px;
}

.timeline-month {
    font-size: 14px;
    font-weight: bold;
}

.timeline-year {
    font-size: 12px;
    color: #888;
}

.timeline-line-container {
    position: relative;
    padding-top: 10px; /* Reduce padding to move the line closer to the month/year header */
}

.timeline-events-row {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.timeline-events-row.bill-row {
    margin-top: 20px; /* Increase margin to add space between the line and the bill events */
}

.timeline-events-row.settopbox-row {
    margin-top: 10px; /* Space the SetTopBox row further from the bills */
}

.timeline-event {
    display: inline-block;
    position: relative;
    margin: 0 5px;
}

.timeline-event .timeline-point {
    width: 12px;
    height: 12px;
    background-color: #999;
    border-radius: 50%;
    position: relative;
}

.timeline-event.settopbox .timeline-point {
    background-color: green;
}

.timeline-event.bill .timeline-point {
    background-color: #ff6200;
}

.timeline-event.empty .timeline-point {
    background-color: #ddd;
}

.timeline-line-container::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    background-color: #ddd;
    z-index: -1;
}

.timeline-line {
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.timeline-line.active {
    background-color: green;
}

.timeline-line.inactive {
    background-color: red;
}

.timeline-slot:first-child .timeline-line {
    display: none;
}

/* Legend */
.legend {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensure the legend stays on top of the timeline */
}

.legend-item {
    margin: 0 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #555;
}

.legend-point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}

.legend-line {
    width: 30px;
    height: 4px;
    margin-right: 5px;
}

.horizontal-timeline-container::-webkit-scrollbar {
    height: 10px;
}

.horizontal-timeline-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}