@tailwind base;
@tailwind components;
@tailwind utilities;

/* General App styles */
.App {
  text-align: center;
  font-family: Arial, sans-serif; /* Use a modern, clean font */
  color: #333; /* Dark gray text color for better readability */
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box;
  font-size: calc(12px + 1.5vmin); /* Adjust font size for better scaling */
  color: white;
}

.App-logo {
  height: 30vmin; /* Reduce logo size slightly for a cleaner look */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear; /* Speed up animation slightly */
  }
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.App-link:hover {
  color: #21a1f1; /* Darken link color on hover for better contrast */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Button styles */
button {
  transition: transform 0.3s ease-in-out, background-color 0.3s ease; /* Add background color transition */
  padding: 10px 20px; /* Add padding for better button size */
  border: none;
  border-radius: 5px; /* Rounded corners for a modern look */
  background-color: #3fa9f5; /* Primary button color */
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  transform: scale(1.05);
  background-color: #2e8bda; /* Darken background on hover */
}

/* Input styles */
input {
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow transition */
  padding: 10px; /* Add padding for better input size */
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 5px;
  width: 100%; /* Make input fill its container */
  box-sizing: border-box;
}

input:focus {
  border-color: #3fa9f5; /* Your primary color */
  outline: none;
  box-shadow: 0 0 5px rgba(63, 169, 245, 0.5); /* Subtle shadow on focus */
}

/* Responsive styles */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(10px + 2vmin); /* Adjust font size for smaller screens */
    padding: 15px; /* Reduce padding on smaller screens */
  }

  .App-logo {
    height: 40vmin; /* Increase logo size slightly on smaller screens */
  }

  button {
    font-size: 14px; /* Slightly smaller buttons on smaller screens */
    padding: 8px 16px;
  }

  input {
    padding: 8px; /* Slightly smaller inputs on smaller screens */
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: calc(8px + 2vmin); /* Further adjust font size for very small screens */
    padding: 10px;
  }

  .App-logo {
    height: 50vmin; /* Increase logo size for very small screens */
  }

  button {
    font-size: 13px; /* Smaller buttons on very small screens */
    padding: 7px 14px;
  }

  input {
    padding: 7px; /* Smaller inputs on very small screens */
  }
}

/* Ensure the MapComponent stays behind the ControlPanel */
.map-container {
  position: relative;
  z-index: 10; /* Lower z-index */
}

.control-panel {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 50; /* Higher z-index to be above the map */
}

.custom-node-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
