/* Tailwind CSS Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
body {
  @apply bg-background text-text transition-all duration-300;
}

button {
  @apply transition duration-300 ease-in-out;
}

button:hover {
  @apply transform scale-105;
}

.navbar {
  @apply bg-white shadow-md py-4;
}

.navbar a {
  @apply text-primary hover:underline;
}

.card {
  @apply bg-white p-8 rounded-lg shadow-lg text-center;
}

.card img {
  @apply mx-auto mb-4 w-24 h-24;
}

.card h1 {
  @apply text-4xl font-bold text-primary mb-4;
}

.card p {
  @apply text-lg text-gray-600 mb-8;
}

.card .buttons {
  @apply space-x-4;
}

.card button {
  @apply px-4 py-2 rounded-md;
}
